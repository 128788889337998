import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import TerrainLogoRelief from "../../assets/TERRAIN-f6f6f6-transparency-digital.png";

import styles from "./landing.module.css";

import Button from "../../components/Button/Button";

const Landing = () => {
  const [hoverImg, setHoverImg] = useState(null);
  const [imgPosition, setImgPosition] = useState({ x: 0, y: 0 });

  const [showText, setShowText] = useState(false);

  const handleClick = () => {
    setShowText(true);
  };

  const handleImageMouseMove = (e) => {
    setImgPosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <div
      className={styles["container"]}
      onMouseMove={handleImageMouseMove}
      style={{ position: "relative" }}
    >
      <div className={styles["logotext"]} onClick={handleClick}>
        <img
          src={TerrainLogoRelief}
          alt="terrainlogo"
          className={styles["terrainlogo"]}
        />
      </div>
      <div className={styles["ent-wrap"]}>
        <Button
          label="ENTER"
          pathname={"/home"}
          className={styles["buttonStyle"]}
        />
      </div>
    </div>
  );
};

export default Landing;

import React, { useContext, useState, useEffect } from "react";
import useMousePosition from "./useMousePosition";
import { CursorContext } from "./CursorContextProvider";
import { useLocation } from "react-router-dom";
import cursorImage from "../../assets/cursor12.png";

import MobileCursor from "./MobileCursor";

const cursorBank = [
  "cursor1.png",
  "cursor2.png",
  "cursor4.png",
  "cursor5.png",
  "cursor6.png",
  "cursor7.png",
  "cursor8.png",
  "cursor9.png",
  "cursor10.png",
  "cursor11.png",
  "cursor12.png",
];
const getRandomCursor = (array) => {
  const randomIndex = Math.floor(Math.random() * array.length);
  return randomIndex;
};

const Cursor = ({ showSub }) => {
  const { clientX, clientY } = useMousePosition();
  const [cursor] = useContext(CursorContext);
  const [trail, setTrail] = useState([]);
  const [frameCount, setFrameCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  const [activePages, setActivePages] = useState(["/manifesto", "/home", "/"]);
  const isTrailActive = activePages.includes(location.pathname);
  const [randomData, setRandomData] = useState(() =>
    getRandomCursor(cursorBank)
  );
  const pathname = location.pathname;
  const [isMobile, setIsMobile] = useState();

  const [zIndexNum, setZIndexNum] = useState(0);

  const handleCursorMove = (e) => {
    // If the viewport is larger than 768px, set the cursor trail position to the mouse position
    const newTrailElement = {
      x: e.clientX,
      y: e.clientY,
    };
    setTrail((prevTrail) => [...prevTrail, newTrailElement]);
  };

  const clearTrail = () => {
    setTrail((prevTrail) => {
      return prevTrail.slice(1); // Remove the first element from the trail array
    });
  };

  const frame = () => {
    setFrameCount((prevCount) => prevCount + 1);
    clearTrail(); // Decrement the trail length on each frame
  };

  const checkkMobile = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    setIsVisible(true);
    setRandomData(getRandomCursor(cursorBank));

    if (pathname.includes("/event/")) {
      setZIndexNum(50);
    }
    if ((pathname === "/manifesto", "/", "/home")) {
      setZIndexNum(-25);
    }
    if (pathname === "/index") {
      setZIndexNum(100);
    }
    if (pathname === "/archive") {
      setZIndexNum(100);
    }
    if (pathname === "/contact") {
      setZIndexNum(100);
    }
    if (pathname === "/about") {
      setZIndexNum(100);
    }
    if (pathname === "/bioassembly") {
      setZIndexNum(100);
    }
    if (pathname === "/gallery") {
      setZIndexNum(100);
    }
    if (pathname === "/studio") {
      setZIndexNum(100);
    }
    if (pathname === "/services") {
      setZIndexNum(100);
    }
    if (pathname === "/bookshop") {
      setZIndexNum(100);
    }
  }, [pathname]);

  useEffect(() => {
    const handleMouseEnter = () => setIsVisible(true);
    const handleMouseLeave = () => setIsVisible(false);
    document.body.addEventListener("mouseenter", handleMouseEnter);
    document.body.addEventListener("mouseleave", handleMouseLeave);
    return () => {
      document.body.removeEventListener("mouseenter", handleMouseEnter);
      document.body.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  // useEffect(() => {
  //   const frameInterval = setInterval(() => {
  //     frame();
  //   }, 477 / 10); // 60 frames per second, adjust as needed

  //   return () => clearInterval(frameInterval);
  // }, []); // Empty dependency array to run the effect only once on page load

  useEffect(() => {
    document.addEventListener("mousemove", handleCursorMove);
    return () => {
      document.removeEventListener("mousemove", handleCursorMove);
    };
  }, []);

  return (
    <>
      {isMobile && activePages ? (
        <MobileCursor
          isMobile={isMobile}
          zIndexNum={zIndexNum}
          cursorImage={cursorBank[randomData]}
        />
      ) : (
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: zIndexNum,
            pointerEvents: "none",
          }}
          onMouseMove={isTrailActive ? handleCursorMove : null}
        >
          {isTrailActive ? (
            trail.map((trailElement, index) => (
              <img
                key={index}
                src={
                  pathname.includes("/event/")
                    ? cursorImage
                    : cursorBank[randomData]
                }
                width={20}
                height={20}
                style={{
                  position: "absolute",
                  zIndex: zIndexNum,
                  left: trailElement.x,
                  top: trailElement.y,
                  transform: `translate(-50%, -50%) scale(${
                    cursor.active ? 3 : 3
                  })`,
                  transition: "transform .2s ease-in-out",
                  opacity: isVisible && clientX > 1 ? 1 : 2,
                }}
              ></img>
            ))
          ) : (
            <img
              src={
                pathname.includes("/event/")
                  ? cursorImage
                  : cursorBank[randomData]
              }
              width={20}
              height={20}
              style={{
                position: "absolute",
                left: clientX,
                top: clientY,
                zIndex: zIndexNum,
                transform: `translate(-50%, -50%) scale(${
                  cursor.active ? 1 : 2
                })`,
                transition: "transform .2s ease-in-out",
                opacity: isVisible && clientX > 1 ? 1 : 0,
              }}
            ></img>
          )}
        </div>
      )}
    </>
  );
};

export default Cursor;

import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import styles from "./service.module.css";
import ServicesMenu from "../../components/ServicesMenu/ServicesMenu.js";
import Footer from "../../components/Footer/Footer.js";

const ServicesPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const section = queryParams.get("section") || undefined;

  const servicesRef = useRef(null);
  const workshopsRef = useRef(null);
  const consultancyRef = useRef(null);
  const spacehireRef = useRef(null);

  const scrollToSection = (ref) => {
    const element = ref.current;
    if (element) {
      const offsetTop = element.getBoundingClientRect().top + window.scrollY;
      const offset = offsetTop - 160; // Adjust this value as needed

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (section === "services") {
      scrollToSection(servicesRef);
    }
    if (section === "workshops") {
      scrollToSection(workshopsRef);
    }
    if (section === "consultancy") {
      scrollToSection(consultancyRef);
    }
    if (section === "spacehire") {
      scrollToSection(spacehireRef);
    }
  }, [section]);

  return (
    <div className={styles["container"]}>
      <div className={styles["outer"]}>
        <div className={styles["servicesContent"]}>
          <ServicesMenu
            servicesRef={servicesRef}
            workshopsRef={workshopsRef}
            consultancyRef={consultancyRef}
            spacehireRef={spacehireRef}
            scrollToSection={scrollToSection}
          />
          <div className={styles["ServicesSection"]}>
            <div id="services" ref={servicesRef} className={styles["textbox"]}>
              <h5>SERVICES</h5>
              <p>
                We offer ecologically oriented services, through consultancy,
                studio space, workshops and public engagements, in addition to
                initiating collaborative projects, prototypes, and spaces.
                <br></br>
                <br></br>
                If you’re interested in working with us on a project or would
                like to engage TERRAIN in some capacity, please get in touch.
              </p>
            </div>
          </div>
          <div className={styles["ServicesSection"]}>
            <div
              id="workshops"
              ref={workshopsRef}
              className={styles["textbox"]}
            >
              <h5>WORKSHOPS</h5>
              <p>
                Workshops activate our vision across a variety of environments,
                with hands-on nature-orienting activities and experiences. These
                may vary from school groups, to corporate team building to
                professional development spaces. We can host groups in our
                space, or we can come to you.
                <br></br>
                <br></br>
                Our workshops on offer rotate seasonally, so please get in touch
                with us to ask what’s currently on offer and for all booking
                requests.
              </p>
            </div>
          </div>
          <div className={styles["ServicesSection"]}>
            <div
              id="consultancy"
              ref={consultancyRef}
              className={styles["textbox"]}
            >
              <h5>CONSULTANCY</h5>
              <p>
                Organisations and industries must proactively prepare for the
                environmental challenges and future that’s already here.
                <br></br>
                <br></br>
                We offer consulting and project services to help organisations
                and individuals integrate ecological ethics and more-than-human
                perspectives into their work.
                <br></br>
                <br></br>
                Our capabilities include:
                <br></br>
                <br></br>/ &nbsp;Concept development, project management,
                production, curation, spatial and experience design, and
                research.
                <br></br>
                <br></br>/ &nbsp;Public engagement, speaking, mentorship,
                writing contributions, media.
                <br></br>
                <br></br>/ &nbsp;Applied sustainability for your organisation,
                business or project.
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <a href="/contact">Contact us</a> if you are interested in
                working together.
              </p>
            </div>
          </div>
        </div>

        <div className={styles["ServicesSection"]}>
          <div id="spacehire" ref={spacehireRef} className={styles["textbox"]}>
            <h5>SPACE HIRE</h5>
            <p>
              Have a private event in mind? Our bookshop and gallery are
              available to hire to our community, which offers audio-visual
              capabilities, including surround sound and large displays for
              immersive experiences in an intimate setting. We have a seated
              capacity of 21 on our aluminium benches, and are happy to discuss
              alternative spatial arrangements and settings to try to
              accommodate your event and intended experience.
              <br></br>
              <br></br>
              Send us your enquiry and be sure to include details of what you
              have in mind. Please note that given the nature of our space, not
              all requests will be accepted.
              <br></br>
              <br></br>
              If you’re interested in a community programming event instead,
              we’ll redirect you to{" "}
              <a
                href="https://forms.gle/R8akjpiGW1J5ufet8"
                target="_blank"
                rel="noreferrer"
              >
                this form
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ServicesPage;

import React from "react";
import { useState, useEffect } from "react";
import { useContext, useCallback } from "react";
import { CursorContext } from "../Cursor/CursorContextProvider";

import styles from "./image.module.css";

const Image = ({ setImage, imageTitle, zoomedImage, mobileImage }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [imageSrc, setImageSrc] = useState(setImage);
  const [, setCursor] = useContext(CursorContext);

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const toggleCursor = useCallback(() => {
    setCursor(({ active }) => ({ active: !active }));
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 970) {
        setImageSrc(mobileImage);
      } else {
        setImageSrc(setImage);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [setImage, mobileImage]);

  return (
    <div className={styles["imageCont"]}>
      <img
        src={imageSrc}
        className={styles["imageStyle"]}
        alt={imageTitle}
        onClick={toggleZoom}
        onMouseEnter={toggleCursor}
        onMouseLeave={toggleCursor}
      />

      {isZoomed && (
        <div className={styles["zoomedCont"]}>
          <img
            src={zoomedImage}
            className={styles["zoomedStyle"]}
            alt={imageTitle}
            onClick={toggleZoom}
            onMouseLeave={setCursor(false)}
          />
        </div>
      )}
    </div>
  );
};

export default Image;

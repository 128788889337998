import React from "react";

import Image from "../../components/Image/Image";
import styles from "../BioassemblyPage/page-template.module.css";
import Footer from "../../components/Footer/Footer.js";

import smallStudioImage from "../../assets/small-images/small-studio_img.jpg";
import medStudioImage from "../../assets/med-images/med-studio_img.jpg";
import StudioImage from "../../assets/large-images/large-studio_img.jpg";

const StudioPage = () => {
  return (
    <>
      <div className={styles["outer"]}>
        <div className={styles["left-col"]}>
          <div className={styles["text-img"]}>
            <span>
              <h1>STUDIO</h1>
            </span>
          </div>
        </div>

        <div className={styles[("container", "column")]}>
          <div>
            <Image
              setImage={smallStudioImage}
              mobileImage={medStudioImage}
              zoomedImage={StudioImage}
              imageTitle={"The Anomolous Image by Trent Crawford."}
            />
          </div>

          <div className={styles["textbox"]}>
            <p>
              At TERRAIN, we are focused on seeding, producing, and enacting the
              future we envision. Adjacent to our bookshop and gallery, we host
              a dedicated private co-working studio for practitioners of ecology
              across disciplines.
              <br></br>
              <br></br>
              Our private work environment is designed to further and strengthen
              the practices, network, and capabilities of each member by being
              supported and surrounded by likeminds in a space grounded by
              ecological ideas, values and ethics.
              <br></br>
              <br></br>
              Starting in 2024, the studio will host a small group of members
              with ecology at the heart of their practices. Members are
              encouraged to be actively engaged with public events in the
              bookshop and gallery.
              <br></br>
              <br></br>
              If you are interested in being one of our studio members, please
              submit an online expression of interest form{" "}
              <a
                href="https://tally.so/r/wkN0jo"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>{" "}
              to join the waitlist.
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StudioPage;

import React from "react";

import styles from "./quote.module.css";

const Quote = ({ content, quotee }) => {
  return (
    <h3 className={styles["bookshop-quote"]}>
      {content}
      <br />
      <br />
      {quotee}
    </h3>
  );
};

export default Quote;

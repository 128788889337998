import React from "react";

import Image from "../../components/Image/Image";
import styles from "./page-template.module.css";
import Footer from "../../components/Footer/Footer.js";

import smallBioassemblyImage from "../../assets/small-images/small-bioassembly_img.jpg";
import medBioassemblyImage from "../../assets/med-images/med-bioassembly_img.jpg";
import BioassemblyImage from "../../assets/large-images/large-bioassembly_img.jpg";

import Quote from "../../components/Quote/Quote";

const BioassemblyPage = (props) => {
  return (
    <>
      <div className={styles["outer"]}>
        <div className={styles["left-col"]}>
          <span>
            <h1>BIOASSEMBLY</h1>
          </span>
        </div>

        <div className={styles[("container", "column")]}>
          <div>
            <Image
              setImage={smallBioassemblyImage}
              zoomedImage={BioassemblyImage}
              mobileImage={medBioassemblyImage}
              imageTitle={
                "Books from the TERRAIN bookstore. Photo of hands moving the Cyber Feminism Index by Mindy Seu"
              }
            />
          </div>

          <div className={styles["textbox"]}>
            <p>
              Australia’s biodiversity is in crisis with over 1,700 species of
              flora and fauna and whole ecological communities known to be
              threatened and at risk of extinction as listed under Section 178
              of the Environmental Protection and Biodiversity Conservation Act
              1999. Ongoing threats to species loss include: degradation and
              fragmentation of habitat, invasive species, altered fire regimes,
              unsustainable land use, mismanagement of natural resources,
              changes to aquatic environments and water flows, and of course the
              many compounding pressures of climate change with increased
              intensity and variability of natural events.
              <br></br>
              <br></br>
              The Bioassembly (short for Biodiversity Assembly) is TERRAIN’s
              educational-adventures outdoors platform, presented in partnership
              with performance outdoor clothing equipment company{" "}
              <a target="_blank" href="https://arcteryx.com.au/">
                Arc’teryx
              </a>
              .<br></br>
              <br></br>
              <Quote
                content="Action on behalf of life transforms. Because the relationship between self and the world is reciprocal, it is not a question of first getting enlightened or saved and then acting. As we work to heal the earth, the earth heals us."
                quotee="ROBIN WALL KIMMERER"
              />
              <br></br>
              <br></br>
              Each activity is led by a special guest or partner organisation.
              Expect social planting days, hikes, snow, geology, binoculars,
              paddling, & so much more as we visit varied terrains and
              bioregions to appreciate and be actively engaged in their health.
              The Bioassembly aims to enable a heightened connection and
              appreciation of biodiverse ecosystems for a healthy planet within
              a local context, and provide opportunities for ecological
              restoration and service.
              <br></br>
              <br></br>
              The Bioassembly is an ongoing project and will be carried within
              our business model as a social-enterprise. We’re excited to have
              also partnered with{" "}
              <a href="https://www.regeneratingfarms.com.au/" target="_blank">
                Regenerating Farms
              </a>
              , based on the Mornington Peninsula, to facilitate planting day
              events on the ground with us on designated ecological restoration
              sites in Victoria, with a focus on the south-west Gippsland and
              Yarra Valley regions. On planting days, attendees can expect: a
              day-trip excursion with transport, lunch, refreshments, music,
              good company, and well-soiled hands. Proceeds from all Bioassembly
              events are fed back into the facilitation of these planting days.
              <br></br>
              <br></br>
              The core intention of the Bioassembly draws from words shared with
              TERRAIN’s founder by Margarita Mora from{" "}
              <a href="https://www.niatero.org/" target="_blank">
                Nia Tero
              </a>{" "}
              whilst presenting at MIT Media Lab, who posed the question: ‘what
              kind of ancestors will we be?’, as a frame to orient the
              beginnings of each new project. We’d like to think we can become
              the ancestors that finally decided to move slow and mend things,
              rather than continuing to move fast and break things. To do this,
              the Bioassembly prioritises a balance with our other urban
              activities by centering time outside within the elements, and
              tending to soil with our hands - and doing so in good company.
              <br></br>
              <br></br>
              <Quote
                content="I like walking because it is slow, and I suspect that the mind, like the feet, works at about three miles an hour. If this is so, then modern life is moving faster than the speed of thought or thoughtfulness."
                quotee="REBECCA SOLNIT"
              />
              <br></br>
              <br></br>
              We were also motivated by words from John Jordan and Isabele
              Fremeax from the{" "}
              <a href="https://labo.zone/?lang=en" target="_blank">
                Lab of Insurrectionary Imagination
              </a>
              , who emphasised that: ‘only people who have done an
              apprenticeship with nature can be trusted with machines’. This
              ongoing project is therefore a proposition and invitation for all,
              but especially the technologists, designers, engineers, architects
              and the likes of our built and future environments to engage in
              such an apprenticeship with nature prior to designing and
              collaborating with machines, as a moral imperative.
              <br></br>
              <br></br>
              <i>
                Sign up to Topographies e-News for updates. If you would like to
                receive planting day invitations and updates only,{" "}
                <a
                  href="https://manage.kmail-lists.com/subscriptions/subscribe?a=VrUvak&g=YwSCYq"
                  target="_blank"
                >
                  sign-up here
                </a>
                .
              </i>
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BioassemblyPage;

import React from "react";
import { useContext, useCallback } from "react";

import { Link, useLocation } from "react-router-dom";

import { CursorContext } from "../Cursor/CursorContextProvider";

import styles from "./button.module.css";

const Button = ({ pathname, label, className }) => {
  const [, setCursor] = useContext(CursorContext);

  const toggleCursor = useCallback(() => {
    setCursor(({ active }) => ({ active: !active }));
  });

  return (
    <Link
      to={pathname}
      className={className}
      onMouseEnter={toggleCursor}
      onMouseLeave={toggleCursor}
    >
      {label}
    </Link>
  );
};

export default Button;

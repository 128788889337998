import React from "react";
import { useState } from "react";

import ABOUT from "../../assets/hover_thumbnails/ABOUT.jpg";
import BOOKSHOP from "../../assets/hover_thumbnails/BOOKSHOP.jpg";
import CALENDAR from "../../assets/hover_thumbnails/CALENDAR.jpg";
import CONTACT from "../../assets/hover_thumbnails/REACHOUT.jpg";
import FAQ from "../../assets/hover_thumbnails/FAQ.jpg";
import GALLERY from "../../assets/hover_thumbnails/GALLERY.jpg";
import STUDIO from "../../assets/hover_thumbnails/STUDIO.jpg";
import MANIFESTO from "../../assets/hover_thumbnails/MANIFESTO.jpg";
import SERVICES from "../../assets/hover_thumbnails/SERVICES.jpg";
import INDEX from "../../assets/hover_thumbnails/INDEX.jpg";
import BIOASSEMBLY from "../../assets/hover_thumbnails/BIOASSEMBLY.jpg";

import styles from "./homepage.module.css";

const Homepage = () => {
  const [hoverImg, setHoverImg] = useState(null);
  const [imgPosition, setImgPosition] = useState({ x: 0, y: 0 });

  const handleButtonHover = (imagePath) => {
    setHoverImg(imagePath);
  };

  const handleImageMouseMove = (e) => {
    setImgPosition({ x: e.clientX, y: e.clientY });
  };

  const imageStyle = {
    position: "absolute",
    transform: `translate(${imgPosition.x + 10}px, ${imgPosition.y + 10}px)`,
  };

  return (
    <>
      <div
        className={styles["container"]}
        onMouseMove={handleImageMouseMove}
        style={{ position: "relative" }}
      >
        <div className={styles["homepage"]}>
          <div className={styles["textcontent"]}>
            <span>
              <span className={styles["para-text"]}>
                An initiative that creates playful physical and digital spaces
                to remind humans that they are embedded in a more-than-human
                world
                <a
                  href="/manifesto"
                  className={styles["buttonStyle"]}
                  onMouseEnter={() => handleButtonHover(MANIFESTO)}
                  onMouseLeave={() => setHoverImg(null)}
                >
                  *^
                </a>
                .
                <br />
                <br />
                We are a&nbsp;
                <a
                  href="/bookshop"
                  className={styles["buttonStyle"]}
                  onMouseEnter={() => handleButtonHover(BOOKSHOP)}
                  onMouseLeave={() => setHoverImg(null)}
                >
                  BOOKSHOP
                </a>
                ,&nbsp;
                <a
                  href="/gallery"
                  className={styles["buttonStyle"]}
                  onMouseEnter={() => handleButtonHover(GALLERY)}
                  onMouseLeave={() => setHoverImg(null)}
                >
                  GALLERY
                </a>
                , and&nbsp;
                <a
                  href="/studio"
                  className={styles["buttonStyle"]}
                  onMouseEnter={() => handleButtonHover(STUDIO)}
                  onMouseLeave={() => setHoverImg(null)}
                >
                  STUDIO
                </a>
                &nbsp;in Ngár-go/Fitzroy on Wurundjeri Land. Read more&nbsp;
                <a
                  href="/about"
                  className={styles["buttonStyle"]}
                  onMouseEnter={() => handleButtonHover(ABOUT)}
                  onMouseLeave={() => setHoverImg(null)}
                >
                  ABOUT US
                </a>
                , see what’s on in our{" "}
                <a
                  href="/calendar"
                  className={styles["buttonStyle"]}
                  onMouseEnter={() => handleButtonHover(CALENDAR)}
                  onMouseLeave={() => setHoverImg(null)}
                >
                  CALENDAR
                </a>
                , forage through our digital resource{" "}
                <a
                  href="/index"
                  className={styles["buttonStyle"]}
                  onMouseEnter={() => handleButtonHover(INDEX)}
                  onMouseLeave={() => setHoverImg(null)}
                >
                  INDEX
                </a>
                , join a{" "}
                <a
                  href="/bioassembly"
                  className={styles["buttonStyle"]}
                  onMouseEnter={() => handleButtonHover(BIOASSEMBLY)}
                  onMouseLeave={() => setHoverImg(null)}
                >
                  BIOASSEMBLY
                </a>{" "}
                adventure, seek our{" "}
                <a
                  href="/services"
                  className={styles["buttonStyle"]}
                  onMouseEnter={() => handleButtonHover(SERVICES)}
                  onMouseLeave={() => setHoverImg(null)}
                >
                  SERVICES
                </a>{" "}
                or feel free to{" "}
                <a
                  href="/contact"
                  className={styles["buttonStyle"]}
                  onMouseEnter={() => handleButtonHover(CONTACT)}
                  onMouseLeave={() => setHoverImg(null)}
                >
                  REACH OUT
                </a>
                &nbsp;directly.
                <br></br>For any burning questions, read our&nbsp;
                <a
                  href="/about?section=faq"
                  className={styles["buttonStyle"]}
                  onMouseEnter={() => handleButtonHover(FAQ)}
                  onMouseLeave={() => setHoverImg(null)}
                >
                  FAQ
                </a>
                .
                <br />
                <br />
                Oh, and, this is a renewably powered and self-hosted website.
              </span>
            </span>
          </div>
        </div>
        {hoverImg && (
          <div className={styles["hover-img"]} style={imageStyle}>
            <img
              src={hoverImg}
              alt="hoverImage"
              style={{ position: "absolute" }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Homepage;

import React from "react";

import styles from "./SearchBar.module.css";

import { useContext, useCallback } from "react";
import { CursorContext } from "../../../components/Cursor/CursorContextProvider";

const SearchBar = ({ searchString, setSearchString }) => {
  const [, setCursor] = useContext(CursorContext);

  const toggleCursor = useCallback(() => {
    setCursor(({ active }) => ({ active: !active }));
  });
  // Create a function to handle user input and update the searchString state.
  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setSearchString(userInput);
  };

  return (
    <div className={styles["div-wrapper"]}>
      <input
        type="text"
        value={searchString || ""}
        onChange={handleInputChange}
        placeholder="Forage..."
        onMouseEnter={toggleCursor}
        onMouseLeave={() => setCursor(false)}
      />
    </div>
  );
};

export default SearchBar;

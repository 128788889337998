import React from "react";
import { Link } from "react-router-dom";

import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useContext, useCallback } from "react";
import { CursorContext } from "../../../components/Cursor/CursorContextProvider";

import styles from "./resource.module.css";

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
  },
};

const Resource = ({ item }) => {
  const [, setCursor] = useContext(CursorContext);

  const toggleCursor = useCallback(() => {
    setCursor(({ active }) => ({ active: !active }));
  });

  return (
    <div
      className={styles["garden-object"]}
      onMouseEnter={toggleCursor}
      onMouseLeave={() => setCursor(false)}
    >
      <Link
        to={item.externalUrl}
        className={styles["linktext"]}
        target={"_blank"}
      >
        <div className={styles["row"]}>
          <h5>{item.title}</h5>
        </div>

        <p>{documentToReactComponents(item.tagline.json, renderOptions)}</p>
      </Link>
    </div>
  );
};

export default Resource;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const MobileCursor = ({ cursorImage, zIndexNum, isMobile }) => {
  const location = useLocation();
  const [px, setPx] = useState(50); // Position x and y
  const [py, setPy] = useState(50);
  const [dx, setDx] = useState(1); // Direction of movement
  const [dy, setDy] = useState(1);
  const [trail, setTrail] = useState([]); // Trail of last ten positions
  const [frameCount, setFrameCount] = useState(0);

  useEffect(() => {
    if (isMobile) {
      let newPx = px;
      let newPy = py;
      const interval = setInterval(() => {
        // Update x coordinate
        const randX = Math.random() * window.innerWidth * 0.03 * dx;
        let newPx = px + randX;
        if (newPx > window.innerWidth || newPx < 0) {
          setDx(Math.random() < 0.5 ? -1 : 1); // Randomly flip direction
          newPx = Math.max(0, Math.min(window.innerWidth, newPx)); // Clip px between 0 and window.innerWidth
        }
        setPx(newPx);

        // Update y coordinate
        const randY = Math.random() * window.innerHeight * 0.03 * dy;
        let newPy = py + randY;
        if (newPy > window.innerHeight || newPy < 0) {
          setDy(Math.random() < 0.5 ? -1 : 1); // Randomly flip direction
          newPy = Math.max(0, Math.min(window.innerHeight, newPy)); // Clip py between 0 and window.innerHeight
        }
        setPy(newPy);

        // Update trail
        setTrail((prevTrail) => {
          const newTrail = [{ px: newPx, py: newPy }, ...prevTrail];
          if (newTrail.length > 23) {
            newTrail.pop(); // Remove the last element if there are more than 67 elements
          }
          return newTrail;
        });
      }, 48); // Update every 23ms

      // Cleanup function to clear the interval when the component unmounts
      return () => {
        clearInterval(interval);
      };
    }
  }, [isMobile, dx, dy, px, py]);
  return (
    <>
      {["/", "/home", "/manifesto"].includes(location.pathname) && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: zIndexNum,
            pointerEvents: "none",
          }}
        >
          {trail.map((pos, index) => (
            <div
              key={index}
              className="mobileCursor"
              width={50}
              height={50}
              style={{
                position: "fixed",
                top: pos.py + "px",
                left: pos.px + "px",
                zIndex: zIndexNum,
              }}
            >
              <img
                src={cursorImage}
                width={50}
                height={50}
                alt="cursor animate"
              ></img>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default MobileCursor;

import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Header from "./components/Header/Header.js";
import EmailForm from "./components/EmailForm/EmailForm.js";

import AboutPage from "./pages/AboutPage/AboutPage.js";
import ArchivePage from "./pages/ArchivePage/ArchivePage.js";
import BookshopPage from "./pages/BookshopPage/BookshopPage.js";
import CalendarPage from "./pages/CalendarPage/CalendarPage.js";
import ContactPage from "./pages/ContactPage/ContactPage.js";
import EventPage from "./pages/EventPage/EventPage.js";
import GalleryPage from "./pages/GalleryPage/GalleryPage.js";
import BioassemblyPage from "./pages/BioassemblyPage/BioassemblyPage.js";
import StudioPage from "./pages/StudioPage/StudioPage.js";
import GardenPage from "./pages/GardenPage/GardenPage.js";
import Landing from "./pages/LandingPage/Landing.js";
import HomePage from "./pages/HomePage/HomePage.js";
import ManifestoPage from "./pages/ManifestoPage/ManifestoPage.js";
import ServicesPage from "./pages/ServicesPage/ServicesPage.js";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy.js";
import TermsConditions from "./pages/TermsConditions/TermsConditions.js";

import CursorContextProvider from "./components/Cursor/CursorContextProvider.js";
import Cursor from "./components/Cursor/Cursor.js";

import "./App.css";

const App = ({ children }) => {
  const [currentPage, setCurrentPage] = useState("");

  return (
    <BrowserRouter>
      <CursorContextProvider>
        <Cursor />

        <Header currentPage={currentPage} />

        <div className="container">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/archive" element={<ArchivePage />} />
            <Route path="/bookshop" element={<BookshopPage />} />
            <Route path="/calendar" element={<CalendarPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/event/:slug" element={<EventPage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/bioassembly" element={<BioassemblyPage />} />
            <Route path="/studio" element={<StudioPage />} />
            <Route path="/index" element={<GardenPage />} />
            <Route path="/manifesto" element={<ManifestoPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsconditions" element={<TermsConditions />} />
          </Routes>
          <EmailForm />
        </div>
      </CursorContextProvider>
    </BrowserRouter>
  );
};

export default App;

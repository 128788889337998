import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./eventpage.module.css";

import { getProjectBySlug } from "../../api/contenful/utils";

import Footer from "../../components/Footer/Footer.js";

const EventPage = () => {
  const { slug } = useParams();
  const [project, setProject] = useState({});

  useEffect(() => {
    getProjectBySlug(slug).then((response) => {
      const items = response.projectsCollection.items;
      setProject(items[0]);
    });
  }, [slug]);

  const renderNode = (node) => {
    if (node.nodeType === "document") {
      // Render the content of the document
      return node.content.map((content, index) => (
        <div key={index}>{renderNode(content)}</div>
      ));
    } else if (node.nodeType === "paragraph") {
      // Render paragraph content
      return <p>{node.content.map((content, index) => renderNode(content))}</p>;
    } else if (node.nodeType === "embedded-asset-block") {
      // Render embedded assets (images, etc.)
      const assetId = node.data.target.sys.id;
      const asset = project?.description?.links.assets.block.find(
        (asset) => asset.sys.id === assetId
      );
      if (asset) {
        return (
          <>
            <img
              src={asset.url}
              alt={asset.title}
              className={styles["imageStyle"]}
            />
            <span className={styles.imgCaptions}>{asset.description}</span>
          </>
        );
      }
    } else if (node.nodeType === "blockquote") {
      // Render blockquotes
      return (
        <blockquote>
          {node.content.map((content, index) => renderNode(content))}
        </blockquote>
      );
    } else if (node.nodeType === "heading-4") {
      // Render heading 4
      return (
        <h4>{node.content.map((content, index) => renderNode(content))}</h4>
      );
    } else if (node.nodeType === "heading-5") {
      // Render heading 5
      return (
        <h5>{node.content.map((content, index) => renderNode(content))}</h5>
      );
    } else if (node.nodeType === "hyperlink") {
      // Render hyperlinks
      return (
        <a href={node.data.uri}>
          {node.content.map((content, index) => renderNode(content))}
        </a>
      );
    } else if (node.nodeType === "text") {
      // Render text content
      if (node.value.trim() === "") {
        // If the text value is an empty string, render a line break
        return <br />;
      } else {
        // Otherwise, render the text
        return node.value;
      }
    } else if (node.nodeType === "unordered-list") {
      // Render unordered lists
      return (
        <ul>
          {node.content.map((item, index) => (
            <li key={index}>{renderNode(item)}</li>
          ))}
        </ul>
      );
    } else if (node.nodeType === "list-item") {
      // Render list items
      return <>{node.content.map((content, index) => renderNode(content))}</>;
    }

    return null;
  };

  const date = new Date(project.date);

  return (
    <>
      <div className={styles["outer"]}>
        <div className={styles[("container", "column")]}>
          <div>
            <h1>{project.title}</h1>

            <h1>{project.subtitle}</h1>

            <h1>{project.date && date.toLocaleDateString("en-AU")}</h1>

            <h1>{project.location}</h1>
          </div>
        </div>

        <div className={styles["container"]}>
          <div className={styles["imageCont"]}>
            <img
              src={project?.thumbnail?.url}
              className={styles["imageStyle"]}
              alt={project?.thumbnail?.title}
            />
          </div>
          <div className={styles["textbox"]}>
            <span>
              {project?.description?.json &&
                renderNode(project?.description?.json)}
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EventPage;

import React, { useState, useContext, useCallback } from "react";

import styles from "./contact.module.css";

import Footer from "../../components/Footer/Footer.js";
import { CursorContext } from "../../components/Cursor/CursorContextProvider";

const ContactPage = () => {
  const [showSub, setShowSub] = useState(false);
  const [, setCursor] = useContext(CursorContext);

  const toggleSubscribe = () => {
    setShowSub((prevShowSub) => !prevShowSub);
  };

  const toggleCursor = useCallback(() => {
    setCursor(({ active }) => ({ active: !active }));
  });

  return (
    <>
      <div className={styles["outer"]}>
        <div className={styles["contactPage"]}>
          <div className={styles["container"]}>
            <div className={styles["column"]}>
              <div className={styles["row-col"]}>
                <span className={styles["rotation"]}>PHYSICAL</span>
                <span className={styles["details"]}>
                  <p>
                    101-103 Brunswick Street, Fitzroy, VIC, 3065, Australia
                    <br />
                    <br />
                    M Closed <br />
                    T Closed <br />
                    W By Appointment <br />
                    T 12 - 19:00 <br />
                    F 12 - 19:00 <br />
                    S 11 - 17:00 <br />
                    S 11 - 17:00 <br />
                    <br />
                    <br />
                    P.O Box 4196, Fitzroy, VIC, 3065, Australia
                    <br />
                    <br />
                    Telephone: (03) 9969 7111 <br />
                    <br />
                  </p>
                </span>
              </div>
            </div>

            <div className={styles["column"]}>
              <div className={styles["row-col"]}>
                <span className={styles["rotation"]}>DIGITAL</span>

                <span className={styles["links"]}>
                  <div>
                    <a
                      href="https://www.instagram.com/terrain.earth/"
                      target="_blank"
                      onMouseEnter={toggleCursor}
                      onMouseLeave={toggleCursor}
                    >
                      INSTAGRAM
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.are.na/terrain"
                      target="_blank"
                      onMouseEnter={toggleCursor}
                      onMouseLeave={toggleCursor}
                    >
                      ARE.NA
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://open.spotify.com/user/1232587233?si=00d0fc16457843a0"
                      target="_blank"
                      onMouseEnter={toggleCursor}
                      onMouseLeave={toggleCursor}
                    >
                      SPOTIFY
                    </a>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div className={styles["container-2"]}>
            <div className={styles["column"]}>
              <div className={styles["textbox"]}>
                <p>
                  General enquiries: hello@TERRAIN.earth
                  <br></br>
                  Book related enquiries: books@TERRAIN.earth
                  <br></br>
                  <br></br>
                  <a
                    href="/privacypolicy"
                    onMouseEnter={toggleCursor}
                    onMouseLeave={toggleCursor}
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="/termsconditions"
                    onMouseEnter={toggleCursor}
                    onMouseLeave={toggleCursor}
                  >
                    Terms and Conditions
                  </a>
                  .<br></br>
                  <br></br>
                  This is a renewably-powered self-hosted website.
                  <br></br>
                  <br></br>
                  <a
                    className={styles["menuBtn"]}
                    onClick={() => toggleSubscribe() && setCursor(true)}
                    onMouseEnter={toggleCursor}
                    onMouseLeave={toggleCursor}
                  >
                    Subscribe
                  </a>{" "}
                  {showSub ? (
                    <>
                      <div
                        id="subscribe"
                        className={styles["sub-wrap"]}
                        onClick={() => setShowSub(false) && setCursor()}
                        onMouseEnter={toggleCursor}
                        onMouseLeave={toggleCursor}
                      ></div>
                      <div className={styles["sub-inner"]}>
                        <div class="klaviyo-form-WdFuEM"></div>
                      </div>
                    </>
                  ) : null}
                  to the Topographies newsletter for musings from the cave,
                  events, exhibitions, and other TERRAIN Projects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;

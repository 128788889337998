import React from "react";
import { Link, useLocation } from "react-router-dom";
import TerrainLogo from "../../assets/TERRAIN-logo-scaleable.svg";
import styles from "./header.module.css";
import { useEffect, useState } from "react";
import { useContext, useCallback } from "react";
import { CursorContext } from "../Cursor/CursorContextProvider";

import Button from "../Button/Button.js";

const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [, setCursor] = useContext(CursorContext);

  const [menuVisibility, setMenuVisibility] = useState(false);
  const [burgerContent, setBurgerContent] = useState("burger");

  const [showSub, setShowSub] = useState(false);

  const toggleSubscribe = () => {
    setShowSub((prevShowSub) => !prevShowSub);
  };

  const toggleCursor = useCallback(() => {
    setCursor(({ active }) => ({ active: !active }));
  });

  const toggleMenuVisibility = () => {
    setMenuVisibility(!menuVisibility);
    setBurgerContent(menuVisibility ? "burger" : "exit");
  };

  const isHomePage = (pathname === "/") | (pathname === "/home");

  useEffect(() => {
    window.scrollTo(0, 0);
    setMenuVisibility(false);
    setBurgerContent("burger");
  }, [location]);

  let currentPage = "";
  if (pathname === "/") {
    currentPage = "home";
  } else if (pathname === "/about") {
    currentPage = "about";
  } else if (pathname === "/archive") {
    currentPage = "archive";
  } else if (pathname === "/bookshop") {
    currentPage = "bookshop";
  } else if (pathname === "/calendar") {
    currentPage = "calendar";
  } else if (pathname === "/contact") {
    currentPage = "contact";
  } else if (pathname === "/event/:slug") {
    currentPage = "event/:slug";
  } else if (pathname === "/gallery") {
    currentPage = "gallery";
  } else if (pathname === "/garden") {
    currentPage = "garden";
  } else if (pathname === "/manifesto") {
    currentPage = "manifesto";
  } else if (pathname === "/services") {
    currentPage = "services";
  } else if (pathname === "/index") {
    currentPage = "index";
  } else if (pathname === "/archive") {
    currentPage = "archive";
  } else if (pathname === "/bioassembly") {
    currentPage = "bioassembly";
  } else if (pathname === "/studio") {
    currentPage = "studio";
  }

  return (
    <>
      <div className={!isHomePage ? styles["header"] : styles["headerHidden"]}>
        {!isHomePage && (
          <div className={styles["row"]}>
            {/* Header content */}
            <div className={styles["terrainlogoscaleable"]}>
              <Link
                to="/home"
                onMouseEnter={toggleCursor}
                onMouseLeave={toggleCursor}
              >
                <img
                  src={TerrainLogo}
                  alt="terrain logo"
                  className={styles["terrainlogo"]}
                />
              </Link>
            </div>

            {/* Navigation */}

            <div
              className={styles["navigation"]}
              onMouseLeave={() => setCursor(false)}
            >
              <div className={styles["menucolumn"]}>
                <Button
                  label="MANIFESTO"
                  pathname="/manifesto"
                  className={
                    currentPage === "manifesto"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />

                <Button
                  label="ABOUT"
                  pathname="/about"
                  className={
                    currentPage === "about"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
                <Button
                  label="CALENDAR"
                  pathname="/calendar"
                  className={
                    currentPage === "calendar"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
              </div>

              <div className={styles["menucolumn"]}>
                <Button
                  label="BOOKSHOP"
                  pathname="/bookshop"
                  className={
                    currentPage === "bookshop"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />

                <Button
                  label="GALLERY"
                  pathname="/gallery"
                  className={
                    currentPage === "gallery"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
                <Button
                  label="STUDIO"
                  pathname="/studio"
                  className={
                    currentPage === "studio"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
              </div>
              <div className={styles["menucolumn"]}>
                <Button
                  label="BIOASSEMBLY"
                  pathname="/bioassembly"
                  className={
                    currentPage === "bioassembly"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
                <Button
                  label="INDEX"
                  pathname="/index"
                  className={
                    currentPage === "index"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />

                <Button
                  label="SERVICES"
                  pathname="/services"
                  className={
                    currentPage === "services"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
              </div>
              <div className={styles["menucolumn"]}>
                <Button
                  label="ARCHIVE"
                  pathname="/archive"
                  className={
                    currentPage === "archive"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
                <Button
                  label="CONTACT"
                  pathname="/contact"
                  className={
                    currentPage === "contact"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
                <button
                  className={styles["menuBtn"]}
                  onClick={() => toggleSubscribe() && setCursor(true)}
                  onMouseEnter={toggleCursor}
                  onMouseLeave={toggleCursor}
                >
                  SUBSCRIBE
                </button>
                {showSub ? (
                  <>
                    <div
                      id="subscribe"
                      className={styles["sub-wrap"]}
                      onClick={() => setShowSub(false) && setCursor()}
                      onMouseEnter={toggleCursor}
                      onMouseLeave={toggleCursor}
                    ></div>
                    <div className={styles["sub-inner"]}>
                      <div class="klaviyo-form-WdFuEM"></div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            {/* Burger Menu */}
            <div className={styles["burger-outer"]}>
              <div
                className={styles["burger"]}
                onClick={toggleMenuVisibility}
                onMouseEnter={toggleCursor}
                onMouseLeave={toggleCursor}
              >
                {burgerContent === "burger" ? (
                  <React.Fragment>
                    <span>---</span>
                    <span>---</span>
                    <span>---</span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span>\_/</span>
                    <br></br>
                    <span>/ \</span>
                  </React.Fragment>
                )}
              </div>
            </div>
            {/* Mobile Navigation */}
            <div
              className={` ${styles["mobile-nav"]} ${
                menuVisibility ? "" : styles["hidden"]
              }`}
            >
              <div className={styles["menucolumn"]}>
                <Button
                  label="MANIFESTO"
                  pathname="/manifesto"
                  className={
                    currentPage === "manifesto"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />

                <Button
                  label="ABOUT"
                  pathname="/about"
                  className={
                    currentPage === "about"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
                <Button
                  label="CALENDAR"
                  pathname="/calendar"
                  className={
                    currentPage === "calendar"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
              </div>

              <div className={styles["menucolumn"]}>
                <Button
                  label="BOOKSHOP"
                  pathname="/bookshop"
                  className={
                    currentPage === "bookshop"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />

                <Button
                  label="GALLERY"
                  pathname="/gallery"
                  className={
                    currentPage === "gallery"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
                <Button
                  label="STUDIO"
                  pathname="/studio"
                  className={
                    currentPage === "studio"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
              </div>
              <div className={styles["menucolumn"]}>
                <Button
                  label="BIOASSEMBLY"
                  pathname="/bioassembly"
                  className={
                    currentPage === "bioassembly"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
                <Button
                  label="INDEX"
                  pathname="/index"
                  className={
                    currentPage === "index"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />

                <Button
                  label="SERVICES"
                  pathname="/services"
                  className={
                    currentPage === "services"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
              </div>
              <div className={styles["menucolumn"]}>
                <Button
                  label="ARCHIVE"
                  pathname="/archive"
                  className={
                    currentPage === "archive"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
                <Button
                  label="CONTACT"
                  pathname="/contact"
                  className={
                    currentPage === "contact"
                      ? styles["currentLink"]
                      : styles["menuBtn"]
                  }
                />
                <button
                  className={styles["menuBtn"]}
                  onClick={() => toggleSubscribe() && setCursor(true)}
                  onMouseEnter={toggleCursor}
                  onMouseLeave={toggleCursor}
                >
                  SUBSCRIBE
                </button>
              </div>
            </div>
            {showSub ? (
              <>
                <div
                  id="subscribe"
                  className={styles["sub-wrap"]}
                  onClick={() => setShowSub(false) && setCursor()}
                  onMouseEnter={toggleCursor}
                  onMouseLeave={toggleCursor}
                ></div>
                <div className={styles["sub-inner"]}>
                  <div class="klaviyo-form-WdFuEM"></div>
                </div>
              </>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
